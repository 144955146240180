import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RightArrow } from "traec-react/utils/entities";

import MetricCategories from "./metricCategories";
import ProjectContextWrapper from "../context";
import useApi from "storybook-dashboard/utils/fetching";
import { useFullIds } from "../utils/hooks";

function TemplateSelect({ templates, setTemplate }) {
  if (!templates) return null;

  let options = templates
    .map((tracker, i) => (
      <option key={i} value={i}>
        {tracker.getIn(["project", "name"])}
      </option>
    ))
    .unshift(
      <option key={-1} value={""}>
        Select a template:
      </option>
    );
  return (
    <select
      className={`custom-select col-sm-12`}
      onChange={(e) => setTemplate(templates.get(e.target.value))}
      defaultValue={""}
    >
      {options}
    </select>
  );
}

function TemplateTree({ template }) {
  let { commitId, trackerId } = useFullIds();
  if (!template) return null;

  // Get the actual template tracker root_ref
  let templateRefId = template?.getIn(["root_master", "uid"]);
  let templateCommitId = template?.getIn(["root_master", "latest_commit", "uid"]);

  let contextIds = {
    projectId: template?.getIn(["project", "uid"]),
    trackerId: template?.get("uid"),
    commitId: templateCommitId,
    refId: templateRefId,
    copyToCommit: { trackerId, commitId },
  };

  return (
    <ProjectContextWrapper params={contextIds}>
      <MetricCategories />
    </ProjectContextWrapper>
  );
}

function CompanyName({ company }) {
  if (!company) return null;
  return <Link to={`/company/${company.get("uid")}`}>{company.get("name")}</Link>;
}

export default function ProjectTemplateMetrics(props) {
  let { project, company } = useFullIds();
  let { data: templates } = useApi("/api/tracker/?onlyTemplates=true");
  let [template, setTemplate] = useState(null);

  return (
    <>
      <h3>Add Metrics from Template</h3>
      <p>
        <CompanyName {...{ company }} /> <RightArrow /> {project?.get("name")}
      </p>

      <div className="row">
        {/* Render the template tree*/}
        <div className="col-sm-6">
          <h5>Template Metrics</h5>

          {/* Dropdown for selecting Template to pull from */}
          <TemplateSelect {...{ templates, setTemplate }} />

          {/* Render the metric tree from the template */}
          <TemplateTree {...{ template }} />
        </div>
        {/* Render the current Metric Tree*/}
        <div className="col-sm-6">
          <h5>Current Metrics</h5>

          <ProjectContextWrapper>
            <MetricCategories />
          </ProjectContextWrapper>
        </div>
      </div>
    </>
  );
}
