import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Modal, useModal } from "storybook-dashboard/components/modal";
import { Spinner } from "traec-react/utils/entities";
import useApi from "storybook-dashboard/utils/fetching";
import Im from "immutable";
import DataTable from "react-data-table-component";
import Moment from "moment";

const NodeContext = React.createContext();

const getParentPath = (path) => path.slice(0, -7);

const intToPath = (i) => i.toString(36).toUpperCase().padStart(7, "0");

const pathToInt = (path) => parseInt(path, 36);

const incrementPath = (path) => {
  let childPath = path.slice(-7);
  let nextChildPath = intToPath(pathToInt(childPath) + 1);
  return path.slice(0, -7) + nextChildPath;
};

const updatePathPrefix = (path, oldPrefix, newPrefix) => {
  if (!path.startsWith(oldPrefix)) return path;
  return newPrefix + path.slice(oldPrefix.length);
};

const updatePathPrefixes = (nodesByPath) => {
  // Categories (aka Issues like Materials, Air Quality, etc) will not always
  // be at the same path for every report so try to go through all the nodes
  // and remap them to distinct new path that correspond to each category
  if (!nodesByPath) return Im.Map();

  // Adjust path prefixes so that
  let categories = nodesByPath.toList().filter((i) => i.get("_type") === "tree");

  // Map category names to new unique paths
  let categoryNamePathMap = categories.reduce((a, i) => {
    let name = i.get("name");
    if (a.get(name)) return a;
    let path = intToPath(1) + intToPath(a.size + 1);
    return a.set(name, path);
  }, Im.Map());

  console.log("updatePathPrefixes", categories?.toJS(), categoryNamePathMap?.toJS());

  // Update all path prefixes for the new categories
  let _nodesByPath = categories.reduce((a, category) => {
    let categoryName = category.get("name");
    let oldPrefix = category.get("_path");
    let newPrefix = categoryNamePathMap.get(categoryName);

    // Get a mapping of old paths to new paths
    let pathMap = a.reduce((m, node) => {
      let path = node.get("_path");
      if (!path) return m;
      let newPath = updatePathPrefix(path, oldPrefix, newPrefix);
      if (path == newPath) return m;
      return m.set(path, newPath);
    }, Im.Map());

    // Update the nodesByPath
    return pathMap.reduce((_a, newPath, path) => {
      let node = _a.get(path);
      if (!node) return _a;
      let _newPath = newPath;
      if (node.get("_type") !== "tree") {
        while (_a.has(_newPath)) {
          _newPath = incrementPath(_newPath);
        }
      }
      if (_newPath !== newPath) {
        console.log("updatePathPrefixes duplicate path", newPath, " ==> ", _newPath);
      }
      return _a.set(_newPath, node.set("_sourcepath", path).set("_path", _newPath)).delete(path);
    }, a);
  }, nodesByPath);

  // TODO: Check and fix possible duplicate paths that are created from updatePathPrefix

  return _nodesByPath;
};

const getNodesByPath = (nodes) => {
  return (nodes || Im.List()).reduce((a, i) => {
    let path = i.get("path");
    let type = i.get("type");
    let _node = i.getIn(["node", type]).set("_path", path).set("_type", type);
    return a.set(path, _node);
  }, Im.Map());
};

const setChildPaths = (nodesByPath) => {
  return nodesByPath.reduce((a, node) => {
    let path = node.get("_path");
    return a.updateIn([getParentPath(path), "_children"], (v) => (v || Im.List()).push(path));
  }, nodesByPath);
};

const componentMap = {
  tree: CategoryRow,
  metricscore: MetricRow,
};

function TabbedContent({ tabs }) {
  let [currentTab, setCurrentTab] = useState(0);

  let tabItems = tabs.map((item, i) => (
    <li key={i} className="nav-item" role="presentation">
      <a
        className={`nav-link ${currentTab == i ? "active" : ""}`}
        style={{ cursor: "pointer" }}
        onClick={() => setCurrentTab(i)}
      >
        {item?.title}
      </a>
    </li>
  ));

  return (
    <>
      <ul className="nav nav-tabs mt-5" id="aggReportTab" role="tablist">
        {tabItems}
      </ul>

      <div className="tab-content" id="aggReportContent">
        <div className="tab-pane fade show active">{tabs[currentTab]?.content}</div>
      </div>
    </>
  );
}

function SubNode({ path, indent = 0 }) {
  let { nodesByPath } = useContext(NodeContext);
  let node = nodesByPath.get(path);
  let Component = componentMap[node.get("_type")];
  if (!Component) return null;
  return <Component node={node} indent={indent} />;
}

function MetricRow({ node, indent = 0 }) {
  let { setModal } = useModal();
  let { valuesByBaseMetricId, reportIdsStr, reportDetailsById } = useContext(NodeContext);
  let childPaths = node.get("_children") || Im.List();
  let baseMetric = node.get("metric");
  let baseMetricId = baseMetric.get("uid");
  let value = valuesByBaseMetricId?.get(baseMetricId);
  return (
    <>
      <div style={{ paddingLeft: `${indent}rem` }}>
        {node.getIn(["metric", "name"])}
        <span
          className="float-right"
          style={{ cursor: "pointer" }}
          onClick={() => setModal(<ValuesPerReportModal {...{ baseMetric, reportIdsStr, reportDetailsById }} />)}
        >
          {value?.toFixed(2)} {baseMetric.get("unit", "")}
        </span>
      </div>
      {childPaths.map((path, i) => (
        <SubNode key={i} path={path} indent={indent + 1} />
      ))}
    </>
  );
}

function CategoryBody({ node }) {
  let childPaths = node.get("_children") || Im.List();
  return (
    <div className="striped-container mt-3">
      {childPaths.map((path, i) => (
        <SubNode key={i} path={path} indent={1} />
      ))}
    </div>
  );
}

function CategoryRow({ node }) {
  let childPaths = node.get("_children") || Im.List();
  return (
    <>
      <div className="mt-2">
        <b>{node.get("name")}</b>
      </div>
      <div className="striped-container">
        {childPaths.map((path, i) => (
          <SubNode key={i} path={path} indent={1} />
        ))}
      </div>
    </>
  );
}

const columns = [
  // {
  //   name: "Company",
  //   format: (r) => {
  //     return (
  //       <Link to={`/company/${r.getIn(["project", "company", "uid"])}`}>
  //         <u>{r.getIn(["project", "company", "name"])}</u>
  //       </Link>
  //     );
  //   },
  //   selector: (r) => r.getIn(["project", "company", "name"]),
  //   sortable: true,
  // },
  // {
  //   name: "Project",
  //   format: (r) => {
  //     return (
  //       <Link to={`/project/${r.getIn(["project", "uid"])}`}>
  //         <u>{r.getIn(["project", "name"])}</u>
  //       </Link>
  //     );
  //   },
  //   selector: (r) => r.getIn(["project", "name"]),
  //   sortable: true,
  // },
  {
    name: "Reporting Package",
    format: (r) => {
      return (
        <Link to={`/project/${r.getIn(["project", "uid"])}/wpack/${r.getIn(["ref", "uid"])}/report/${r.get("uid")}`}>
          <u>{r.getIn(["ref", "name"])}</u>
        </Link>
      );
    },
    selector: (r) => r.getIn(["ref", "name"]),
    sortable: true,
  },
  {
    name: "Reporting Period",
    format: (r) =>
      `${Moment(r.getIn(["reporting_period_data", "startDate"])).format("Do MMM YY")} - ${Moment(
        r.getIn(["reporting_period_data", "endDate"]),
      ).format("Do MMM YY")}`,
    sortable: true,
    selector: (r) => r.getIn(["reporting_period_data", "startDate"]),
  },
  {
    name: "Value",
    sortable: true,
    selector: (r) => r.get("value"),
  },
  {
    name: "Unit",
    sortable: true,
    selector: (r) => r.getIn(["metric", "unit"]),
  },
];

function ValuesPerReportModal({ baseMetric, reportIdsStr, reportDetailsById }) {
  let baseMetricId = baseMetric.get("uid");

  let { data: valuesByReport } = useApi("/api/dashboard/report/values/inputs?reports={reportIdsStr}", {
    reportIdsStr,
  });

  let reportValues = valuesByReport
    ?.map((values, reportId) =>
      reportDetailsById?.get(reportId).set("value", values?.get(baseMetricId)).set("metric", baseMetric),
    )
    ?.toList()
    ?.filter((i) => i.get("value") != null);

  console.log("ValuesPerReportModal", baseMetric?.toJS(), valuesByReport?.toJS());

  return (
    <Modal title={`Reported values for metric: ${baseMetric.get("name")}`}>
      <DataTable data={reportValues?.toArray()} columns={columns} />
    </Modal>
  );
}

const mapByKey = (items, key = "uid") => items?.reduce((a, i) => a.set(i.get(key), i), Im.Map());

export default function AggregateMetricsPage() {
  let reportIdsStr = new URL(window.location.href).searchParams.get("reports");
  const history = useHistory();

  // "_nodes" is a list of all unique base metrics under this company / business unit level
  let { data: _nodes, isLoading: isLoadingNodes } = useApi("/api/tracker/node/?reports={reportIdsStr}", {
    reportIdsStr,
  });

  // This is just to pre-load the data so that it loads faster on the first modal click
  let { data: valuesByReport, isLoading: isLoadingValues } = useApi(
    "/api/dashboard/report/values/inputs?reports={reportIdsStr}",
    {
      reportIdsStr,
    },
  );

  let { data: projects, isLoading: isLoadingProjects } = useApi(`/api/project/`);
  let { data: refs, isLoading: isLoadingRefs } = useApi(`/api/tracker/ref/`);
  let { data: commits, isLoading: isLoadingCommits } = useApi(`/api/tracker/commit/?requiresAction=False`);

  if (isLoadingNodes || isLoadingValues || isLoadingProjects || isLoadingRefs || isLoadingCommits) {
    return <Spinner />;
  }

  // Reduce down all report details to a mapping by reportId (used in modals for showing report details/links)
  let projectsById = mapByKey(projects);
  let refsById = mapByKey(refs);
  let reportDetailsById = mapByKey(commits)?.map((i) =>
    i.set("project", projectsById?.get(i.get("project"))).set("ref", refsById?.get(i.get("ref"))),
  );

  // Derive the valuesByBaseMetricId from the valuesByReport
  let valuesByBaseMetricId = valuesByReport?.reduce((a, c) => {
    return c.reduce((_a, v, k) => {
      let curVal = _a.get(k);
      let newVal = curVal == null && v == null ? null : (curVal || 0) + (v || 0);
      return _a.set(k, newVal);
    }, a);
  }, Im.Map());

  // Change the list of nodes at assorted paths into a consistent path so that it appears
  // like the metrics have come from one report
  let nodesByPath = getNodesByPath(_nodes).filter((i) => i.get("_path"));
  nodesByPath = updatePathPrefixes(nodesByPath);
  nodesByPath = setChildPaths(nodesByPath);

  // Get the categories (issues)
  let categories = nodesByPath
    .toList()
    .filter((i) => i.get("_type") === "tree")
    .sortBy((i) => i.get("_path"));

  return (
    <div className="container">
      <NodeContext.Provider value={{ nodesByPath, valuesByBaseMetricId, reportIdsStr, reportDetailsById }}>
        <h3>Summary metrics report</h3>
        <button
          className="btn btn-sm btn-outline-primary float-right"
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </button>
        <div style={{ clear: "both" }} />
        <TabbedContent
          tabs={categories?.toArray()?.map((node, i) => ({
            title: node.get("name"),
            content: <CategoryBody key={i} node={node} />,
          }))}
        />
        {/* {categories.map((node, i) => (
          <CategoryRow key={i} node={node} />
        ))} */}
      </NodeContext.Provider>
    </div>
  );
}
